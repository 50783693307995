import { Link } from "react-router-dom";
import style from "./404.module.css";

export const Page404 = () => {
    return (
        <section className={style.main}>
            <div className={style.wrapper}>
                <h1>PAGE NOT FOUND</h1>
                <p>
                    Try refreshing or get back to <Link to="/">Home Page</Link>
                </p>
            </div>
        </section>
    );
};
