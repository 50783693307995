import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { client } from "../../client";
import style from "./Header.module.css";

export const Header = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [vimeoBgSrc, setVimeoBgSrc] = useState("");
    const [vimeoBgSrcMobile, setVimeoBgSrcMobile] = useState("");
    const [showreelURL, setShowreelURL] = useState("");
    const [showreelURLMobile, setShowreelURLMobile] = useState("");

    useEffect(() => {
        const fetchVideosURLs = () =>
            client.fetch('*[_type == "homeVideos"]').then((result) => {
                setVimeoBgSrc(result[0].homeBgVideo);
                setVimeoBgSrcMobile(result[0].homeBgVideoMobile);
                setShowreelURL(result[0].reelVideo);
                setShowreelURLMobile(result[0].reelVideoMobile);
            });
        fetchVideosURLs();
    }, []);

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://player.vimeo.com/api/player.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        const resizeEvent = window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth);
        });

        return () => {
            window.removeEventListener("resize", resizeEvent);
        };
    }, []);

    const handleReelPlay = () => {
        const wrapper = document.createElement("div");
        wrapper.style =
            "height:100lvh; width:100%; overflow:hidden; background-color: #000; position: fixed; top: 0; left: 0; z-index:100; display:flex; justify-content: center; align-items: center;";
        wrapper.id = "reelWrapper";

        const fullScreenPlayer = document.createElement("iframe");
        fullScreenPlayer.src =
            windowWidth > 435 ? showreelURL : showreelURLMobile;
        fullScreenPlayer.setAttribute("frameborder", "0");
        fullScreenPlayer.setAttribute("allowfullscreen", "");
        fullScreenPlayer.setAttribute("autopla", "");
        fullScreenPlayer.setAttribute("allow", "autoplay; fullscreen");
        fullScreenPlayer.style =
            "z-index:101; width:100vmax; min-height:100%; overflow: hidden;";

        const closeButton = document.createElement("div");
        closeButton.textContent = "+";
        closeButton.style =
            "font-size: 5rem; text-transform:uppercase; cursor:pointer; color: #fff; position: absolute; right: 3rem; top:3rem; z-index:102; rotate: 45deg;";
        closeButton.addEventListener("click", closeReelPlay);

        wrapper.appendChild(fullScreenPlayer);
        wrapper.appendChild(closeButton);
        document.body.appendChild(wrapper);
    };

    const closeReelPlay = () => {
        document.querySelector("#reelWrapper").remove();
    };

    return (
        <section className={style.headerWrapper}>
            {windowWidth > 835 ? (
                <>
                    <div className={style.playerWrapper}>
                        <ReactPlayer
                            className={style.reactPlayer}
                            url={vimeoBgSrc}
                            playing
                            loop
                            muted
                            playsinline
                            height={(windowWidth / 16) * 9}
                            width="100%"
                        />
                    </div>
                    <div className={style.headerBottom}>
                        <a
                            className={style.watchWrapper}
                            onClick={handleReelPlay}
                        >
                            <div className={style.playIcon}></div>
                            <div className={style.watchReel}>
                                WATCH DIGIMENTAL SHOWREEL
                            </div>
                        </a>

                        <div className={style.address}>
                            DIGIMENTAL STUDIO LTD. <br />
                            PAUL STREET EC2A 4NE <br />
                            LONDON - UNITED KINGDOM <br />[ +48 ] 604 - 343 -
                            523
                        </div>
                        <div className={style.exploreWrapper}>
                            <a href="#headline">
                                <div className={style.arrow}></div>
                                <div className={style.explore}>
                                    SCROLL TO EXPLORE
                                </div>
                            </a>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={style.playerWrapper}>
                        <div className={style.address}>
                            DIGIMENTAL STUDIO LTD. <br />
                            PAUL STREET EC2A 4NE <br />
                            LONDON - UNITED KINGDOM <br />[ +48 ] 604 - 343 -
                            523
                        </div>
                        <a
                            className={style.watchWrapper}
                            onClick={handleReelPlay}
                        >
                            <div className={style.playIcon}></div>
                            <div className={style.watchReel}>
                                WATCH DIGIMENTAL SHOWREEL
                            </div>
                        </a>
                        <ReactPlayer
                            className={style.reactPlayer}
                            url={vimeoBgSrcMobile}
                            playing
                            loop
                            muted
                            playsinline
                            height={(windowWidth / 9) * 16}
                            width="100vw"
                        />
                    </div>
                </>
            )}
        </section>
    );
};
