export const Icon20 = ({ hex = "#fff", width, height, className }) => {
    return (
        <div className={className} style={{ height: height, width: width }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 74.924 50.909">
                <g id="Group_264" data-name="Group 264" transform="translate(0 0)">
                    <g id="Group_263" data-name="Group 263">
                        <path
                            id="Path_796"
                            data-name="Path 796"
                            d="M2.1,12.01a18.115,18.115,0,0,1,3.359-6.3,15.207,15.207,0,0,1,5.637-4.2A19.093,19.093,0,0,1,18.906,0,19.507,19.507,0,0,1,25.312,1.05a16.555,16.555,0,0,1,5.356,3.011A14.246,14.246,0,0,1,34.344,8.9a14.8,14.8,0,0,1,1.368,6.441,15.506,15.506,0,0,1-1.193,6.371,16.671,16.671,0,0,1-3.151,4.726,26.8,26.8,0,0,1-4.445,3.747q-2.485,1.683-5.008,3.326t-4.9,3.537a22.212,22.212,0,0,0-4.2,4.412H35.991V50H0a20.416,20.416,0,0,1,1.226-7.423,19.706,19.706,0,0,1,3.326-5.638,29.223,29.223,0,0,1,4.934-4.584q2.839-2.1,5.988-4.272,1.612-1.12,3.432-2.276a23.2,23.2,0,0,0,3.327-2.555A13.722,13.722,0,0,0,24.752,20.1,7.83,7.83,0,0,0,25.766,16.1,7.388,7.388,0,0,0,23.7,10.538a7.3,7.3,0,0,0-5.287-1.994,6.437,6.437,0,0,0-3.676,1.015,7.572,7.572,0,0,0-2.415,2.662,12.129,12.129,0,0,0-1.294,3.64,20.829,20.829,0,0,0-.386,3.955H1.119A23.466,23.466,0,0,1,2.1,12.01"
                            fill={hex}
                        />
                        <path
                            id="Path_797"
                            data-name="Path 797"
                            d="M40.367,13.272a21.673,21.673,0,0,1,3.887-7.81,13.926,13.926,0,0,1,5.708-4.2,19.681,19.681,0,0,1,13.863,0,14.2,14.2,0,0,1,5.744,4.2,21.349,21.349,0,0,1,3.919,7.81,42.973,42.973,0,0,1,1.436,12.007,44.679,44.679,0,0,1-1.436,12.29,21.314,21.314,0,0,1-3.919,7.877,14.188,14.188,0,0,1-5.744,4.2,19.681,19.681,0,0,1-13.863,0,13.919,13.919,0,0,1-5.708-4.2,21.636,21.636,0,0,1-3.887-7.877,44.729,44.729,0,0,1-1.433-12.29,43.02,43.02,0,0,1,1.433-12.007m8.649,17.084a27.292,27.292,0,0,0,.875,5.741,12.268,12.268,0,0,0,2.347,4.727,5.633,5.633,0,0,0,4.62,1.961,5.826,5.826,0,0,0,4.727-1.961A12.06,12.06,0,0,0,63.967,36.1a27.422,27.422,0,0,0,.875-5.741q.139-2.975.14-5.077c0-.839-.014-1.854-.036-3.047a34.276,34.276,0,0,0-.279-3.676,34.89,34.89,0,0,0-.7-3.744A11.5,11.5,0,0,0,62.6,11.485,7.51,7.51,0,0,0,60.291,9.1a6.2,6.2,0,0,0-3.433-.908,6,6,0,0,0-3.394.908,7.309,7.309,0,0,0-2.243,2.382,12.873,12.873,0,0,0-1.365,3.327,25.915,25.915,0,0,0-.7,3.744q-.208,1.891-.243,3.676t-.035,3.047q0,2.1.139,5.077"
                            fill={hex}
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
};
