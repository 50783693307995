import style from "./ListingItem.module.css";

export const ListingItem = ({
    imageUrl = "../../assets/jpg/img1.jpg",
    title,
    desc,
    hoverString,
    imgHeight = "100%",
    color = "#FFF",
}) => {
    return (
        <div className={style.wrapper}>
            <div className={style.imgWrapper} style={{ height: imgHeight }}>
                <div className={style.overlayText}>{hoverString}</div>
                <div
                    className={style.img}
                    style={{ backgroundImage: `url(${imageUrl})`, height: "100%", width: "100%" }}
                ></div>
            </div>
            <div className={style.title} style={{ color: color }}>
                {title}
            </div>
            <div className={style.desc} style={{ color: color }}>
                {desc}
            </div>
        </div>
    );
};
