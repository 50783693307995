import React from "react";

export const UpRightArrow = ({ hex = "#fff", width = "auto", height = "100%" }) => {
    return (
        <div style={{ height: height, width: width }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 9.947 9.947">
                <g
                    id="Icon_feather-arrow-up-right"
                    data-name="Icon feather-arrow-up-right"
                    transform="translate(-9.439 -9.439)"
                >
                    <path
                        id="Path_802"
                        data-name="Path 802"
                        d="M10.5,18.326,18.326,10.5"
                        fill="none"
                        stroke={hex}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <path
                        id="Path_803"
                        data-name="Path 803"
                        d="M10.5,10.5h7.826v7.826"
                        fill="none"
                        stroke={hex}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                </g>
            </svg>
        </div>
    );
};
