import style from "./People.module.css";
import { ListingItem } from "../../Components/ListingItem/ListingItem";
import { client } from "../../client";
import { useEffect, useState } from "react";
import ImageUrlBuilder from "@sanity/image-url";
import defaultAvatar from "../../assets/jpg/img1.jpg";

export const People = () => {
    const [teamData, setTeamData] = useState([]);
    const imageBuilder = ImageUrlBuilder(client);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const updateWindowWidthOnResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", updateWindowWidthOnResize);

        return () => {
            window.removeEventListener("resize", updateWindowWidthOnResize);
        };
    }, []);

    const fetchData = () => {
        client.fetch('*[_type == "team"]').then((result) => {
            setTeamData(result);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={style.moduleWrapper}>
            <div className={style.smallTitle}>PEOPLE</div>
            <h2 className={style.title}>Get to know the different faces behind Digimental®</h2>
            <div className={style.gridWrapper}>
                {teamData.map((elem) => {
                    return (
                        <ListingItem
                            imageUrl={elem.image ? imageBuilder.image(elem.image) : defaultAvatar}
                            title={elem.name}
                            desc={elem.position}
                            hoverString={elem.greeting}
                            imgHeight={windowWidth > 835 ? "23.6vw" : `${((windowWidth * 0.92) / 576) * 667}px`}
                            color="#000"
                            key={elem._id}
                        />
                    );
                })}
            </div>
        </div>
    );
};
