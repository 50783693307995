export const IconD1 = ({ hex = "#fff", width, height, className }) => {
    return (
        <div className={className} style={{ height: height, width: width }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 68.609 49.998">
                <g id="Group_266" data-name="Group 266" transform="translate(0 0)">
                    <g id="Group_265" data-name="Group 265">
                        <path
                            id="Path_798"
                            data-name="Path 798"
                            d="M21.568,0a25.73,25.73,0,0,1,9,1.542,19.537,19.537,0,0,1,7.212,4.62,21.342,21.342,0,0,1,4.76,7.7,31.17,31.17,0,0,1,1.718,10.853,34.68,34.68,0,0,1-1.4,10.085,22.227,22.227,0,0,1-4.237,7.982,19.842,19.842,0,0,1-7.073,5.287A23.955,23.955,0,0,1,21.568,50H0V0ZM20.8,40.755a14.1,14.1,0,0,0,4.62-.771,10.188,10.188,0,0,0,3.991-2.555,12.855,12.855,0,0,0,2.8-4.658,20.457,20.457,0,0,0,1.051-7,29.245,29.245,0,0,0-.733-6.828,13.664,13.664,0,0,0-2.418-5.216A10.708,10.708,0,0,0,25.665,10.4a17.753,17.753,0,0,0-6.827-1.154H11v31.51Z"
                            fill={hex}
                        />
                        <path
                            id="Path_799"
                            data-name="Path 799"
                            d="M58.666,50V18.206H46.341V10.714a23.358,23.358,0,0,0,5.008-.385A13.045,13.045,0,0,0,55.69,8.72a10.652,10.652,0,0,0,3.255-3.047A10.818,10.818,0,0,0,60.7.982h7.913V50Z"
                            fill={hex}
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
};
