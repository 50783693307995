import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../assets/svg/Logo";
import style from "./MobileMenu.module.css";
import plusIcon from "../../assets/svg/plusIcon.svg";

export const MobileMenu = ({ fontColor = "inherit" }) => {
    const [toggle, setToggle] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);

    useEffect(() => {
        setToggle((toggle) => !toggle);
        document.querySelector(`.${style.menuToggleIcon}`).classList.remove(style.clicked);
        document.querySelector(`.${style.menuWrapper}`).classList.remove(style.active);
    }, [window.location.pathname]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [toggle, scrollPos]);

    const handleScroll = () => {
        if (typeof window != "undefined") {
            if (!toggle && window.scrollY > 100) {
                window.scrollY > scrollPos
                    ? document.querySelector(`.${style.navWrapper}`).classList.add(style.hideMenu)
                    : document.querySelector(`.${style.navWrapper}`).classList.remove(style.hideMenu);
            }
            setScrollPos(window.scrollY);
        }
    };

    const handleToggle = (e) => {
        setToggle((toggle) => !toggle);
        if (!toggle) {
            document.querySelector(`.${style.menuToggleIcon}`).classList.add(style.clicked);
            document.querySelector(`.${style.menuWrapper}`).classList.add(style.active);
            document.querySelector(`.${style.logo}`).classList.remove(style.hideMenu);
        } else {
            document.querySelector(`.${style.menuToggleIcon}`).classList.remove(style.clicked);
            document.querySelector(`.${style.menuWrapper}`).classList.remove(style.active);
        }
    };

    return (
        <div className={style.moduleWrapper}>
            <div className={style.navWrapper}>
                <Link to="/">
                    <Logo color={fontColor} className={style.logo} />
                </Link>
                <div className={style.menuIconWrapper} onClick={handleToggle}>
                    <div className={style.menuToggleIcon} style={{ color: fontColor }}>
                        <img src={plusIcon} alt="plus" style={{ height: "4vw" }} />
                    </div>
                </div>
            </div>
            <div className={style.menuWrapper}>
                <Link to="/">HOMEPAGE</Link>
                <Link to="/about">ABOUT US</Link>
                <Link to="/portfolio">DIGITAL SHOWCASE</Link>
                <Link to="/offer">OFFER</Link>
                <Link to="/contact">CONTACT</Link>
            </div>
        </div>
    );
};
