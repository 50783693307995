import style from "./LetsBuild.module.css";

export const LetsBuild = () => {
    const handleHover = () => {
        const line = document.querySelector(`#line`);
        line.classList.contains(style.lineHovered)
            ? line.classList.remove(style.lineHovered)
            : line.classList.add(style.lineHovered);
    };

    return (
        <section className={style.moduleWrapper}>
            <div className={style.textWrapper}>
                <div className={style.firstLine}>Let’s build.</div>
                <div className={style.secondLine} onMouseEnter={handleHover} onMouseLeave={handleHover}>
                    <span className={style.secondLineText}>
                        <a href="mailto:info@digimental.com">info@digimental.com</a>
                    </span>
                    <div id="line" className={style.line}></div>
                </div>
            </div>
        </section>
    );
};
