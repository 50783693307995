import React from "react";
import { FullWidthPicture } from "./FullWidthPicture/FullWidthPicture";
import { FullWidthText } from "./FullWidthText/FullWidthText";
import { FullWidthTextWithPicture } from "./FullWidthTextWithPicture/FullWidthTextWithPicture";
import { TwoPictures } from "./TwoPictures/TwoPictures";
import { TwoPicturesWithText } from "./TwoPicturesWithText/TwoPicturesWithText";
import { HorizontalVideo } from "./HorizontalVideo/HorizontalVideo";

export const Components = (content) => {
    const componentsList = {
        fullWidthPicture: FullWidthPicture,
        fullWidthText: FullWidthText,
        fullWidthTextWithPicture: FullWidthTextWithPicture,
        twoPictures: TwoPictures,
        twoPicturesWithText: TwoPicturesWithText,
        horizontalVideo: HorizontalVideo,
    };

    return React.createElement(componentsList[content._type], {
        key: content._key,
        content: content,
    });
};
