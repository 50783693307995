export const Digitalanimals = ({ height = "337px", width = `${height * 10.7952}` }) => {
    return (
        <svg
            id="Group_262"
            data-name="Group 262"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 3638 337"
        >
            <path
                id="Path_782"
                data-name="Path 782"
                d="M1482.008,3.655h-66.44l-73.877,215.831L1267.812,3.655h-66.44v0h-33.431V326.3h66.439V100.085L1311.815,326.3h59.75L1449,100.085V326.3h66.438V3.657h-33.431Z"
                transform="translate(1299.078 4.065)"
                fill="#fff"
            />
            <path
                id="Path_783"
                data-name="Path 783"
                d="M976.867,3.656H910.43L799.987,326.3h66.44l20.415-59.642h126.084l20.415,59.642h66.44L989.338,3.656Zm-71.93,210.136L949.883,82.486,994.83,213.792Z"
                transform="translate(889.81 4.066)"
                fill="#fff"
            />
            <rect
                id="Rectangle_232"
                data-name="Rectangle 232"
                width="66.438"
                height="322.642"
                transform="translate(2347.715 7.723)"
                fill="#fff"
            />
            <path
                id="Path_784"
                data-name="Path 784"
                d="M1163.476,217.915,1032.136,3.656h-74.7V326.3h66.438V112.282l130.9,214.016h75.144V3.656h-66.44Z"
                transform="translate(1064.936 4.067)"
                fill="#fff"
            />
            <g id="Group_261" data-name="Group 261">
                <g id="Group_260" data-name="Group 260">
                    <path
                        id="Path_785"
                        data-name="Path 785"
                        d="M225.418,33.926a68.757,68.757,0,0,1-21,19.663A84.145,84.145,0,0,1,176.4,63.969a150.8,150.8,0,0,1-32.318,2.488v52.448h79.536V326.3h66.438V3.655H236.717q-2.7,18.085-11.3,30.271"
                        transform="translate(160.261 4.065)"
                        fill="#fff"
                    />
                    <path
                        id="Path_786"
                        data-name="Path 786"
                        d="M815.546,44.706a18.013,18.013,0,0,0,3.675-11.248,18.637,18.637,0,0,0-5.4-13.554,18.308,18.308,0,0,0-13.664-5.511h-29.6V74.114H778.6V52.07h18.341l14.011,22.044H820.6L806.128,51.151a17.5,17.5,0,0,0,9.419-6.445M778.6,44.48V22.434h21.556a10.858,10.858,0,0,1,8.042,2.983,10.9,10.9,0,0,1,2.989,8.041,10.466,10.466,0,0,1-3.337,7.813,11.91,11.91,0,0,1-8.612,3.209Z"
                        transform="translate(857.08 16.009)"
                        fill="#fff"
                    />
                    <path
                        id="Path_787"
                        data-name="Path 787"
                        d="M239.277,43.424a126.057,126.057,0,0,0-46.544-29.815A166.032,166.032,0,0,0,134.66,3.656H0V326.3H134.66q37.039,0,64.391-12.424a128.046,128.046,0,0,0,45.646-34.12q18.294-21.688,27.341-51.508,9.027-29.837,9.036-65.079,0-40.217-11.083-70.039-11.067-29.812-30.715-49.7M207.859,215.133q-6.777,18.532-18.075,30.058a65.614,65.614,0,0,1-25.753,16.488,90.945,90.945,0,0,1-29.813,4.977H66.438V63.315h55.124q26.187,0,44.058,7.45Q183.464,78.23,194.3,92.228q10.855,14,15.6,33.661a188.432,188.432,0,0,1,4.731,44.058q0,26.659-6.778,45.186"
                        transform="translate(0 4.066)"
                        fill="#fff"
                    />
                    <path
                        id="Path_788"
                        data-name="Path 788"
                        d="M586.62,37.968a68.756,68.756,0,0,1-21,19.663A84.143,84.143,0,0,1,537.6,68.011a146.432,146.432,0,0,1-22.669,2.438q-1.362-2.722-2.871-5.35A120.4,120.4,0,0,0,481.12,29.39,136.32,136.32,0,0,0,439.092,7.484,156.628,156.628,0,0,0,390.957.021q-37.039,0-66.651,13.113a142.328,142.328,0,0,0-49.915,36.15,161.23,161.23,0,0,0-31.19,53.994q-10.83,30.949-10.838,67.092,0,35.268,10.838,65.751a157.094,157.094,0,0,0,31.19,53.092q20.332,22.613,49.915,35.48,29.6,12.867,66.651,12.868a118.8,118.8,0,0,0,46.546-9.71q23.063-9.714,42.026-34.118l7.239,36.608h45.186V175.813H396.394V228.68h67.7c-3.753,13.092-9.962,23.74-18.687,31.851q-19.2,17.848-54.455,17.847-23.934,0-40.668-9.267a77.8,77.8,0,0,1-27.111-24.623,106.869,106.869,0,0,1-15.128-34.58A164.357,164.357,0,0,1,303.3,170.37a176.649,176.649,0,0,1,4.748-41.109,109.059,109.059,0,0,1,15.128-35.478A76.9,76.9,0,0,1,350.289,68.93q16.732-9.242,40.668-9.269,25.762,0,43.847,13.557a60.178,60.178,0,0,1,17.88,22.029c.091.192.194.412.31.661.568,1.187,1.088,2.429,1.607,3.675.382.927.79,1.954,1.206,3.063.539,1.455,1.071,2.919,1.544,4.451.04.125.08.251.12.378q.947,3.105,1.724,6.411a73.645,73.645,0,0,1,1.358,9.062H584.82V330.342h66.438V7.7H597.916q-2.7,18.085-11.3,30.271"
                        transform="translate(258.453 0.023)"
                        fill="#fff"
                    />
                    <path
                        id="Path_789"
                        data-name="Path 789"
                        d="M709.691,3.656H449.849V63.315h96.694V326.3H612.98V63.315h96.711Z"
                        transform="translate(500.358 4.066)"
                        fill="#fff"
                    />
                    <path
                        id="Path_790"
                        data-name="Path 790"
                        d="M725.266,3.656H658.829L548.386,326.3h66.44l20.415-59.642H761.325L781.74,326.3h66.44L737.737,3.656ZM653.337,213.792,698.282,82.486l44.947,131.306Z"
                        transform="translate(609.959 4.066)"
                        fill="#fff"
                    />
                    <path
                        id="Path_791"
                        data-name="Path 791"
                        d="M809.584,3.626A53.125,53.125,0,1,0,862.71,56.752,53.184,53.184,0,0,0,809.584,3.626m0,98.785a45.66,45.66,0,1,1,45.661-45.659,45.71,45.71,0,0,1-45.661,45.659"
                        transform="translate(841.396 4.033)"
                        fill="#fff"
                    />
                    <path
                        id="Path_792"
                        data-name="Path 792"
                        d="M772.273,3.656h-66.44V326.3h166.1v-59.64H772.273Z"
                        transform="translate(785.085 4.066)"
                        fill="#fff"
                    />
                    <path
                        id="Path_793"
                        data-name="Path 793"
                        d="M1571.859,3.656h-66.438V326.3h166.1v-59.64h-99.657Z"
                        transform="translate(1674.451 4.066)"
                        fill="#fff"
                    />
                    <path
                        id="Path_794"
                        data-name="Path 794"
                        d="M1854.232,190.043a96.606,96.606,0,0,0-27.348-27.8,120.409,120.409,0,0,0-31.864-15.139q-16.045-4.746-25.083-6.548-30.287-7.69-49.034-12.659t-29.373-9.942q-10.627-4.974-14.243-10.849t-3.61-15.363q0-10.4,4.514-17.173a39.256,39.256,0,0,1,11.529-11.3,46.067,46.067,0,0,1,15.589-6.324,83.526,83.526,0,0,1,17.181-1.81,121.3,121.3,0,0,1,24.177,2.258,58.434,58.434,0,0,1,19.661,7.687A40.036,40.036,0,0,1,1780.105,80q5.2,9.486,6.1,23.953h68.7q0-28.015-10.616-47.683a92.7,92.7,0,0,0-28.7-32.312,121.475,121.475,0,0,0-41.359-18.309A205.374,205.374,0,0,0,1725.651,0a165.412,165.412,0,0,0-43.392,5.876,121.111,121.111,0,0,0-38.868,18.077,96.222,96.222,0,0,0-27.8,30.5q-10.614,18.317-10.614,43.169,0,22.144,8.358,37.73a79.769,79.769,0,0,0,21.919,26,120.96,120.96,0,0,0,30.734,16.949,307.829,307.829,0,0,0,35.25,11.073q17.629,4.971,34.8,9.038a218.805,218.805,0,0,1,30.734,9.486q13.558,5.428,21.921,13.563t8.358,21.239q0,12.2-6.324,20.109a44.344,44.344,0,0,1-15.821,12.435,71,71,0,0,1-20.335,6.1,141.136,141.136,0,0,1-20.343,1.576,107.9,107.9,0,0,1-27.115-3.386,67.475,67.475,0,0,1-22.823-10.39,51.216,51.216,0,0,1-15.589-18.309q-5.884-11.289-5.877-27.563h-68.7q-.456,29.827,10.849,51.516a101.368,101.368,0,0,0,30.5,35.706,130.214,130.214,0,0,0,44.3,20.565,204.084,204.084,0,0,0,51.749,6.548q32.977,0,58.075-7.676t42.03-21.471a89.482,89.482,0,0,0,25.54-32.768,98.757,98.757,0,0,0,8.582-41.126q0-27.115-11.521-44.521"
                        transform="translate(1773.099 0.001)"
                        fill="#fff"
                    />
                    <path
                        id="Path_795"
                        data-name="Path 795"
                        d="M1524.854,3.656h-66.438L1347.973,326.3h66.44l20.415-59.642h126.084l20.415,59.642h66.44L1537.324,3.656Zm-71.93,210.136,44.945-131.306,44.947,131.306Z"
                        transform="translate(1499.324 4.066)"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    );
};
