import { useEffect, useState } from "react";
import { client } from "../../client";
import { HeaderTitle } from "../../Components/HeaderTitle/HeaderTitle";
import { LetsBuild } from "../../Components/LetsBuild/LetsBuild";
import { OfferCategoryElement } from "../../Components/OfferCategoryElement/OfferCategoryElement";
import { Footer } from "../../Modules/Footer/Footer";
import style from "./Offer.module.css";

export const Offer = () => {
    const [categoriesData, setCategoriesData] = useState([]);

    const fetchData = () => {
        client.fetch('*[_type == "categories"]').then((result) => {
            setCategoriesData(result);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={style.main}>
            <div className={style.smallTitle}>WHAT WE DO</div>
            <HeaderTitle color="#000" title="Digimental Studio is an in-house production house based in London." />
            <div className={style.descRight}>
                THE TEAM OF DESIGNERS, STORYTELLERS, AND PASSIONATE COLLABORATORS, WHO WORK TOGETHER TO CREATE
                INDUSTRY-SHIFTING PRESENTATIONS THAT WIN PEOPLE’S HEARTS AND MINDS.
                <br />
                <br />
                AND WE STRIVE TO BECOME ONE OF THE MOST RECOGNIZABLE & INFLUENTIAL PRESENTATION AGENCIES OF THE TIME WHO
                DOES THAT.
                <br />
                <br />
                ESPECIALLY IN TODAY’S FAST-PACED DIGITAL LANDSCAPE THE FIRST IMPRESSION IS THE ONE THAT MATTERS MOST. WE
                DESIGN REMARKABLE EXPERIENCES THAT ARE EASY-TO-USE, YET IMPOSSIBLE TO FORGET.
            </div>
            {categoriesData.map((elem) => {
                return <OfferCategoryElement elementData={elem} />;
            })}
            <LetsBuild minHeight="80vh" />
            <Footer />
        </div>
    );
};
