import style from "./AboutHeader.module.css";
import { Digitalanimals } from "../../assets/svg/Digitalanimals";
import { HeaderTitle } from "../../Components/HeaderTitle/HeaderTitle";
import { useState } from "react";
import { useEffect } from "react";

export const AboutHeader = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const updateWindowWidthOnResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", updateWindowWidthOnResize);

        return () => {
            window.removeEventListener("resize", updateWindowWidthOnResize);
        };
    }, []);

    return (
        <section className={style.moduleWrapper}>
            <HeaderTitle title="Digimental Studio is an in-house production house based in London." />
            <div className={style.gridWrapper}>
                <div className={style.smallTitle}>ABOUT DIGIMENTAL STUDIO</div>
                <a className={style.arrowLink} href="#digitalAnimals">
                    <div className={style.downArrow}></div>
                </a>
                <div className={style.textField1}>
                    STUDIO WAS ESTABLISHED IN 2011 BY MATEUSZ SYPIEN, ONE OF THE LEADING CREATIVE PROFESSIONALS AND THE
                    HOTTEST MIXED MEDIA ARTIST IN THE INDUSTRY.
                    <br />
                    <br /> STUDIO MISSION IS INSPIRE TO BELIEVE THAT DREAMS COME TRUE AND WE HELP TO MAKE THEM HAPPEN.
                </div>
                <div className={style.textField2}>
                    THE TEAM OF DESIGNERS, STORYTELLERS, AND PASSIONATE COLLABORATORS, WHO WORK TOGETHER TO CREATE
                    INDUSTRY-SHIFTING PRESENTATIONS THAT WIN PEOPLE’S HEARTS AND MINDS.
                    <br />
                    <br /> AND WE STRIVE TO BECOME ONE OF THE MOST RECOGNIZABLE & INFLUENTIAL PRESENTATION AGENCIES OF
                    THE TIME WHO DOES THAT.
                    <br />
                    <br /> ESPECIALLY IN TODAY’S FAST-PACED DIGITAL LANDSCAPE THE FIRST IMPRESSION IS THE ONE THAT
                    MATTERS MOST. WE DESIGN REMARKABLE EXPERIENCES THAT ARE EASY-TO-USE, YET IMPOSSIBLE TO FORGET.
                </div>
                <div className={style.digitalAnimalsWrapper} id="digitalAnimals">
                    <div className={style.digitalAnimals1}>
                        <Digitalanimals height={windowWidth > 835 ? "37vh" : "15vh"} />
                    </div>
                    <div className={style.digitalAnimals2}>
                        <Digitalanimals height={windowWidth > 835 ? "37vh" : "15vh"} />
                    </div>
                </div>
                <div className={style.textField3}>
                    THROUGH PROFOUND DESIGN AESTHETICS, METICULOUSLY CRAFTED DETAILS AND SURPRISING INTERACTIONS WE
                    CREATE MOMENTS OF DELIGHT THAT GO STRAIGHT TO THE SOUL.
                    <br />
                    <br />
                    AESTHETICS WORK, BUT NOT WITHOUT A STRONG FOUNDATION. OUR CREATIVE CONCEPTS ARE BACKED BY AN
                    INSIGHT-BASED APPROACH AND THOROUGH UNDERSTANDING OF YOUR BRAND.
                    <br />
                    <br />
                    THIS FUELS US WITH THE KNOWLEDGE TO STRATEGICALLY SOLVE EVEN THE MOST COMPLEX PROBLEMS. ONLY THEN,
                    WE CAN CREATE DIGITAL EXPERIENCES THAT MAINTAIN A YIN-YANG BALANCE BETWEEN MAGIC AND LOGIC.
                </div>
            </div>
        </section>
    );
};
