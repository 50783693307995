import { AboutHeader } from "../../Modules/AboutHeader/AboutHeader";
import { AboutPictures } from "../../Modules/AboutPictures/AboutPictures";
import { AboutServices } from "../../Modules/AboutServices/AboutServices";
import { Footer } from "../../Modules/Footer/Footer";
import { People } from "../../Modules/People/People";
import style from "./About.module.css";

export const About = () => {
    return (
        <section className={style.main}>
            <AboutHeader />
            <AboutPictures />
            <People />
            <AboutServices />
            <Footer />
        </section>
    );
};
