import style from "./TwoPictures.module.css";
import ImageUrlBuilder from "@sanity/image-url";
import { client } from "../../../../client";

export const TwoPictures = (props) => {
    const imageBuilder = ImageUrlBuilder(client);

    return (
        <div className={style.moduleWrapper}>
            <div
                className={style.firstImage}
                style={{
                    background: `url(${imageBuilder.image(props.content.firstImage)}) no-repeat`,
                    backgroundSize: "cover",
                }}
            ></div>
            <div
                className={style.secondImage}
                style={{
                    background: `url(${imageBuilder.image(props.content.secondImage)}) no-repeat`,
                    backgroundSize: "cover",
                }}
            ></div>
        </div>
    );
};
