import { Link } from "react-router-dom";
import style from "./Navbar.module.css";
import { Logo } from "../../assets/svg/Logo";
import { useState } from "react";
import { useEffect } from "react";

export const Navbar = ({ fontColor = "inherit", lineColor = "#000" }) => {
    const [scrollPos, setScrollPos] = useState(0);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [scrollPos]);

    const handleScroll = () => {
        if (typeof window != "undefined") {
            if (window.scrollY > 100) {
                window.scrollY > scrollPos
                    ? document.querySelector(`.${style.wrapper}`).classList.add(style.hideNavbar)
                    : document.querySelector(`.${style.wrapper}`).classList.remove(style.hideNavbar);
            }
            setScrollPos(window.scrollY);
        }
    };

    return (
        <section className={style.wrapper}>
            <div className={style.line} style={{ backgroundColor: lineColor }}></div>
            <nav className={style.navWrapper} style={{ backgroundColor: "transparent", color: fontColor }}>
                <div className={style.menu}>
                    <span className={style.plusIcon}>+</span>
                    <Link to="/">HOMEPAGE</Link>
                    <Link to="/about">ABOUT US</Link>
                    <Link to="/portfolio">DIGITAL SHOWCASE</Link>
                    <Link to="/offer">OFFER</Link>
                    <Link to="/contact">CONTACT</Link>
                </div>
                <div className={style.logo}>
                    <Link to="/">
                        <Logo color={fontColor} />
                    </Link>
                </div>
            </nav>
        </section>
    );
};
