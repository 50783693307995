import style from "./Contact.module.css";
import { LetsBuild } from "../../Components/LetsBuild/LetsBuild";

export const Contact = () => {
    return (
        <div className={style.main}>
            <>
                <div className={style.buildWrapper} style={{ fontSize: "20px" }}>
                    <LetsBuild minHeight="40vh" />
                </div>
                <div className={style.bottomWrapper}>
                    <div className={style.leftColumn}>
                        <div className={style.contact}>
                            [ +48 ] 604 - 343 - 523
                            <br />
                            INFO@DIGIMENTAL.COM
                        </div>
                        <div className={style.socialWrapper}>
                            <ul>
                                <li>
                                    <a
                                        href="https://www.linkedin.com/company/digimental-studio/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        LINKEDIN
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.behance.net/digimental" target="_blank" rel="noreferrer">
                                        BEHANCE
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/DigimentalLDN" target="_blank" rel="noreferrer">
                                        TWITTER
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.behance.net/digimental" target="_blank" rel="noreferrer">
                                        BEHANCE
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={style.rightColumn}>
                        DIGIMENTAL STUDIO LTD.
                        <br />
                        PAUL STREET EC2A 4NE
                        <br />
                        LONDON - UNITED KINGDOM
                    </div>
                </div>
            </>
        </div>
    );
};
