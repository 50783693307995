import ReactPlayer from "react-player";
import style from "./HorizontalVideo.module.css";

export const HorizontalVideo = (props) => {
    return (
        <div className={style.moduleWrapper}>
            <ReactPlayer
                className={style.reactPlayer}
                url={props.content.videoURL}
                playing
                loop
                muted
                playsinline
                height="calc(100vw / 16 * 9)"
                width="auto"
                style={{
                    marginTop: props.content.marginAround ? "0.84vw" : 0,
                    marginLeft: props.content.marginAround ? "4.2vw" : 0,
                    marginRight: props.content.marginAround ? "4.2vw" : 0,
                }}
            />
        </div>
    );
};
