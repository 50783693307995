import style from "./AboutPictures.module.css";
import { aboutPicturesData } from "./aboutPicturesData";

export const AboutPictures = () => {
    return (
        <div className={style.moduleWrapper}>
            <div className={style.picture1}>
                <img src={aboutPicturesData[0].imageSrc} alt="1" height="100%" />
            </div>
            <div className={style.picture2}>
                <img src={aboutPicturesData[1].imageSrc} alt="2" height="100%" />
            </div>
            <div className={style.picture3}>
                <img src={aboutPicturesData[2].imageSrc} alt="3" height="100%" />
            </div>
        </div>
    );
};
