import { useEffect, useState } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";
import { client } from "../../client";
import style from "./ServicesAccordion.module.css";

export const ServicesAccordion = ({ hideTitle, title, color = "#000", borderColor }) => {
    const [accordionData, setAccordionData] = useState();

    const fetchData = () => {
        const query = `*[_type == "services"]`;
        client.fetch(query).then((result) => setAccordionData(result));
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Accordion allowZeroExpanded className={style.accordionWrapper}>
                {hideTitle ? null : (
                    <div className={style.title} style={{ color: color }}>
                        {title}
                    </div>
                )}
                {accordionData &&
                    accordionData.map((elem) => {
                        return (
                            <AccordionItem
                                className={style.accordionItem}
                                key={elem._id}
                                style={{ color: color, borderColor: `${borderColor}10` }}
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton style={{ color: color }} className={style.accordionButton}>
                                        {elem.title}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel style={{ color: color }} className={style.accordionPanel}>
                                    {elem.desc}
                                </AccordionItemPanel>
                            </AccordionItem>
                        );
                    })}
            </Accordion>
        </>
    );
};
