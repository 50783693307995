import { Route, Routes, useLocation } from "react-router-dom";
import { Navbar } from "./Components/Navbar/Navbar";
import { Home } from "./Pages/Home/Home";
import { About } from "./Pages/About/About";
import { PortfolioPage } from "./Pages/PortfolioPage/PortfolioPage";
import { Contact } from "./Pages/Contact/Contact";
import { Offer } from "./Pages/Offer/Offer";
import { useEffect, useState } from "react";
import { Cookie } from "./Pages/InfoPages/Cookie/Cookie";
import { Privacy } from "./Pages/InfoPages/Privacy/Privacy";
import { Terms } from "./Pages/InfoPages/Terms/Terms";
import { MobileMenu } from "./Components/MobileMenu/MobileMenu";
import { client } from "./client";
import { Project } from "./Components/Project/Project";
import { Page404 } from "./Pages/404/404";

function App() {
    const { pathname } = useLocation();
    const [navColor, setNavColor] = useState("");
    const [navLineColor, setNavLineColor] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [portfolioData, setPortfolioData] = useState([]);

    const fetchData = () => {
        client.fetch('*[_type == "portfolio"]').then((result) => setPortfolioData(result));
    };

    useEffect(() => {
        fetchData();
    }, []);

    const setColorByLocation = () => {
        switch (pathname) {
            case "/":
            case "/about":
                setNavColor("rgba(255,255,255)");
                setNavLineColor("rgba(255,255,255,0.5)");
                break;
            case "/portfolio":
            case "/offer":
            case "/contact":
                setNavColor("rgba(0,0,0)");
                setNavLineColor("rgba(0,0,0,0.5)");
                break;
            default:
                setNavColor("rgba(128,128,128)");
                setNavLineColor("rgba(128,128,128,0.5)");
                break;
        }
    };

    const updateWindowWidthOnResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        const event = window.addEventListener("resize", updateWindowWidthOnResize);

        return () => {
            window.removeEventListener("resize", event);
        };
    }, []);

    useEffect(() => {
        setColorByLocation();
        if (document.readyState === "complete") {
            window.scrollTo(0, 1);
        }
    }, [pathname]);

    return (
        <>
            {windowWidth > 835 ? (
                <Navbar fontColor={navColor} lineColor={navLineColor} />
            ) : (
                <MobileMenu fontColor={navColor} />
            )}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/portfolio/" element={<PortfolioPage />} />
                <Route path="/offer" element={<Offer />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/cookie" element={<Cookie />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                {portfolioData.map((elem) => {
                    return (
                        <Route
                            path={`/portfolio/${elem.project.slug.current}`}
                            element={<Project slug={elem.project.slug.current} />}
                        />
                    );
                })}
                <Route path="*" element={<Page404 />} />
            </Routes>
        </>
    );
}

export default App;
