import ImageUrlBuilder from "@sanity/image-url";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../../client";
import { Footer } from "../../Modules/Footer/Footer";
import { ListingItem } from "../ListingItem/ListingItem";
import style from "./Project.module.css";
import { Components } from "./projectComponents/Components";

export const Project = ({ slug }) => {
    const [projectData, setProjectData] = useState();
    const [portfolioData, setPortfolioData] = useState();
    const [projectContent, setProjectContent] = useState();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const imageBuilder = ImageUrlBuilder(client);

    const updateWindowWidthOnResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", updateWindowWidthOnResize);

        return () => {
            window.removeEventListener("resize", updateWindowWidthOnResize);
        };
    }, []);

    useEffect(() => {
        const fetchSlugData = async () => {
            let query = `*[_type == "portfolio" && project.slug.current == "${slug}"]`;
            let result = await client.fetch(query);
            await setProjectData(result[0]);
            await setProjectContent(result[0].project.content);
            query = `*[_type == "portfolio" && _id in ["${result[0].project.moreProjects[0]._ref}", "${result[0].project.moreProjects[1]._ref}", "${result[0].project.moreProjects[2]._ref}"]]`;
            result = await client.fetch(query);
            await setPortfolioData(result);
        };
        fetchSlugData().catch(console.error);
    }, [slug]);

    return (
        <>
            {projectData && (
                <>
                    <section className={style.moduleWrapper}>
                        <header>
                            <div
                                className={style.image}
                                style={{
                                    backgroundImage: `url(${imageBuilder.image(projectData.project.header.image)})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                }}
                            ></div>

                            <div className={style.infoWrapper}>
                                <div className={style.title}>{projectData.project.title}</div>
                                <div className={style.columnsWrapper}>
                                    <div className={style.leftColumn}>
                                        <div className={style.client}>
                                            PROJECT / CLIENT : {projectData.project.header.client}
                                        </div>
                                        <div className={style.typeOfWork}>
                                            TYPE OF WORK : {projectData.project.header.typeOfWork}
                                        </div>
                                        <div className={style.industries}>
                                            INDUSTRIES : {projectData.project.header.industries}
                                        </div>
                                        <div className={style.year}>YEAR : {projectData.project.header.year}</div>
                                    </div>
                                    <div className={style.rightColumn}>
                                        <div className={style.desc1}>{projectData.project.header.desc1}</div>
                                        <div className={style.desc2}>{projectData.project.header.desc2}</div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {projectContent.map((content) => Components(content))}
                        <div className={style.moreProjectsWrapper}>
                            <h2>Discover more projects</h2>
                            <div className={style.projectsWrapper}>
                                {portfolioData &&
                                    portfolioData.map((elem) => {
                                        return (
                                            <Link to={`/portfolio/${elem.project.slug.current}`}>
                                                <ListingItem
                                                    key={elem._id}
                                                    imageUrl={imageBuilder.image(elem.project.header.image)}
                                                    title={elem.project.title}
                                                    desc={elem.project.subtitle}
                                                    hoverString="View Case Study"
                                                    imgHeight={windowWidth > 835 ? "23.6vw" : "63vw"}
                                                    color="#000"
                                                />
                                            </Link>
                                        );
                                    })}
                            </div>
                        </div>
                    </section>
                    <Footer />
                </>
            )}
        </>
    );
};
