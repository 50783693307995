import style from "./Footer.module.css";
import { Icon20 } from "../../assets/svg/Icon20";
import { IconD1 } from "../../assets/svg/IconD1";
import { IconG1 } from "../../assets/svg/IconG1";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { client } from "../../client";

export const Footer = () => {
    const [portfolioData, setPortfolioData] = useState([]);

    useEffect(() => {
        client.fetch('*[_type == "portfolio"]').then((result) => {
            setPortfolioData(result);
        });
    }, []);

    const handleHover = () => {
        const line = document.querySelector(`#footerLine`);
        line.classList.contains(style.lineHovered)
            ? line.classList.remove(style.lineHovered)
            : line.classList.add(style.lineHovered);
    };
    return (
        <section className={style.footerWrapper}>
            <Icon20
                className={style.icon20}
                hex="#000"
                height="5rem"
                width="4.5vw"
            />
            <div className={style.slogan}>
                DESIGN & CRAFT STUDIO THAT TURNS ORDINARY INTO EXCEPTIONAL. WE
                CREATE BREATHTAKING VISUALS BY BLENDING DESIGN, MOTION, AND
                TECHNOLOGY.
            </div>
            <div className={style.menu}>
                <Link to="/">Home Page</Link>
                <Link to="/about">About Us</Link>
                <Link to="/portfolio">
                    Digital Showcase<sup> {portfolioData.length}</sup>
                </Link>
                <Link to="/offer">Offer</Link>
                <Link to="/contact">Contact</Link>
            </div>
            <IconD1
                className={style.iconD1}
                hex="#000"
                height="5rem"
                width="4vw"
            />
            <div className={style.plusIcon1}>+</div>
            <div className={style.letsBuild}>
                <div className={style.textWrapper}>
                    <div className={style.firstLine}>Let’s build.</div>
                    <div
                        className={style.secondLine}
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                    >
                        <span className={style.secondLineText}>
                            <a href="mailto:info@digimental.com">
                                info@digimental.com
                            </a>
                        </span>
                        <div id="footerLine" className={style.line}></div>
                    </div>
                </div>
            </div>
            <div className={style.address}>
                DIGIMENTAL STUDIO LTD.
                <br />
                PAUL STREET EC2A 4NE
                <br />
                LONDON - UNITED KINGDOM
                <br />[ +48 ] 604 - 343 - 523
            </div>
            <div className={style.year}>© 2011–2022</div>
            <div className={style.cookieMenu}>
                <Link to="/cookie">COOKIE POLICY</Link>
            </div>
            <div className={style.plusIcon2}>+</div>
            <div className={style.socialMedia}>SOCIAL MEDIA :</div>
            <div className={style.socialMediaMenu}>
                <a
                    href="https://www.linkedin.com/company/digimental-studio/"
                    target="_blank"
                    rel="noreferrer"
                >
                    LINKEDIN
                </a>{" "}
                /{" "}
                <a
                    href="https://www.behance.net/digimental"
                    target="_blank"
                    rel="noreferrer"
                >
                    BEHANCE
                </a>{" "}
                /{" "}
                <a
                    href="https://twitter.com/DigimentalLDN"
                    target="_blank"
                    rel="noreferrer"
                >
                    TWITTER
                </a>{" "}
                /{" "}
                <a
                    href="https://www.behance.net/digimental"
                    target="_blank"
                    rel="noreferrer"
                >
                    BEHANCE
                </a>
            </div>
            <div className={style.designedBy}>
                DESIGNED BY :<br />
                <a href="https://247studio.co" target="_blank" rel="noreferrer">
                    TWOFOURSEVEN®STUDIO
                </a>
            </div>
            <IconG1
                className={style.iconG1}
                hex="#000"
                height="5rem"
                width="4vw"
            />
        </section>
    );
};
