import { useState } from "react";
import { Link } from "react-router-dom";
import { Icon20 } from "../../assets/svg/Icon20";
import { IconD1 } from "../../assets/svg/IconD1";
import { IconG1 } from "../../assets/svg/IconG1";
import { UpRightArrow } from "../../assets/svg/UpRightArrow";
import style from "./Headline.module.css";

export const Headline = () => {
    const [arrowHovered, setArrowHovered] = useState(false);

    return (
        <section className={style.headlineWrapper} id="headline">
            <div className={style.topWrapper}>
                <Icon20 className={style.icon20} />
                <IconD1 className={style.iconD1} />
            </div>
            <h1 className={style.headline}>
                Design & Craft Studio that turns ordinary into exceptional. We create breathtaking visuals by blending
                design, motion, and technology.
            </h1>
            <div className={style.bottomWrapper}>
                <Link to="/about" className={style.aboutLink}>
                    <div
                        className={style.aboutUsWrapper}
                        onMouseEnter={(e) => {
                            setArrowHovered(true);
                            e.currentTarget.firstChild.classList.add(style.arrowHovered);
                            e.currentTarget.lastChild.classList.add(style.aboutUsHovered);
                        }}
                        onMouseLeave={(e) => {
                            setArrowHovered(false);
                            e.currentTarget.firstChild.classList.remove(style.arrowHovered);
                            e.currentTarget.lastChild.classList.remove(style.aboutUsHovered);
                        }}
                    >
                        <div className={style.arrow}>
                            {arrowHovered ? (
                                <UpRightArrow hex="#000" height="1rem" />
                            ) : (
                                <UpRightArrow hex="#fff" height="1rem" />
                            )}
                        </div>
                        <div className={style.aboutUs}>READ MORE ABOUT US</div>
                    </div>
                </Link>
                <a href="#portfolio" className={style.downArrowLink}>
                    <div className={style.downArrow}></div>
                </a>
                <IconG1 className={style.iconG1} />
            </div>
        </section>
    );
};
