import ImageUrlBuilder from "@sanity/image-url";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../../client";
import { ListingItem } from "../ListingItem/ListingItem";
import style from "./OfferCategoryElement.module.css";

export const OfferCategoryElement = ({ elementData }) => {
    const [portfolioData, setPortfolioData] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const imageBuilder = ImageUrlBuilder(client);

    const updateWindowWidthOnResize = () => {
        setWindowWidth(window.innerWidth);
    };

    const fetchData = () => {
        client.fetch('*[_type == "portfolio"]').then((result) => {
            setPortfolioData(result);
        });
    };

    useEffect(() => {
        window.addEventListener("resize", updateWindowWidthOnResize);
        fetchData();

        return () => {
            window.removeEventListener("resize", updateWindowWidthOnResize);
        };
    }, []);

    return (
        <div className={style.moduleWrapper}>
            <div className={style.topWrapper}>
                <h2 className={style.title}>{elementData.title}</h2>
                <Link to="/portfolio" state={{ filter: elementData._id }} className={style.viewAllLink}>
                    VIEW ALL PROJECTS
                </Link>
            </div>
            <div className={style.desc}>{elementData.description}</div>
            <div className={style.listingWrapper}>
                {portfolioData.map((elem) => {
                    return (
                        elem.project.category._ref === elementData._id &&
                        elem.project.onOffer && (
                            <Link to={`/portfolio/${elem.project.slug.current}`}>
                                <ListingItem
                                    imageUrl={imageBuilder.image(elem.project.header.image)}
                                    title={elem.project.title}
                                    desc={elem.project.subtitle}
                                    hoverString="View Case Study"
                                    imgHeight={windowWidth > 835 ? "23.6vw" : "63vw"}
                                    color="#000"
                                />
                            </Link>
                        )
                    );
                })}
            </div>
        </div>
    );
};
