import image1 from "../../assets/jpg/about1.jpg";
import image2 from "../../assets/jpg/about2.jpg";
import image3 from "../../assets/jpg/about3.jpg";

export const aboutPicturesData = [
    {
        imageSrc: image1,
    },
    {
        imageSrc: image2,
    },
    {
        imageSrc: image3,
    },
];
