export const IconG1 = ({ hex = "#fff", width, height, className }) => {
    return (
        <div className={className} style={{ height: height, width: width }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 65.003 52.306">
                <g id="Group_268" data-name="Group 268" transform="translate(0 0)">
                    <g id="Group_267" data-name="Group 267">
                        <path
                            id="Path_800"
                            data-name="Path 800"
                            d="M31.789,50.8a18.408,18.408,0,0,1-7.213,1.5,25.646,25.646,0,0,1-10.328-1.994,22.134,22.134,0,0,1-7.736-5.5,24.375,24.375,0,0,1-4.833-8.228A30.251,30.251,0,0,1,0,26.4,31.3,31.3,0,0,1,1.679,16,25.016,25.016,0,0,1,6.512,7.634a22.073,22.073,0,0,1,7.736-5.6A25.252,25.252,0,0,1,24.576,0a24.273,24.273,0,0,1,7.459,1.157,21.14,21.14,0,0,1,6.513,3.394,18.379,18.379,0,0,1,7.106,13.094h-10.5a10.532,10.532,0,0,0-3.78-6.3,11.009,11.009,0,0,0-6.8-2.1,12.874,12.874,0,0,0-6.3,1.436,11.927,11.927,0,0,0-4.2,3.852,16.886,16.886,0,0,0-2.344,5.5,27.35,27.35,0,0,0-.736,6.37,25.451,25.451,0,0,0,.736,6.127,16.543,16.543,0,0,0,2.344,5.358,12.045,12.045,0,0,0,4.2,3.816,12.841,12.841,0,0,0,6.3,1.436q5.464,0,8.438-2.765t3.466-8.02H25.419V24.158H46.425v27.03h-7L38.3,45.515A16.473,16.473,0,0,1,31.789,50.8"
                            fill={hex}
                        />
                        <path
                            id="Path_801"
                            data-name="Path 801"
                            d="M55.061,51.188V18.414H42.736V10.922a23.358,23.358,0,0,0,5.008-.385,13.045,13.045,0,0,0,4.341-1.609A10.652,10.652,0,0,0,55.34,5.881,10.818,10.818,0,0,0,57.091,1.19H65v50Z"
                            fill={hex}
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
};
