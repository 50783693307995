import ImageUrlBuilder from "@sanity/image-url";
import { client } from "../../../../client";
import style from "./TwoPicturesWithText.module.css";

export const TwoPicturesWithText = (props) => {
    const imageBuilder = ImageUrlBuilder(client);

    return (
        <div className={style.moduleWrapper}>
            <div className={style.leftColumn}>
                <div
                    className={style.firstImage}
                    style={{
                        background: `url(${imageBuilder.image(props.content.firstImage)}) no-repeat`,
                        backgroundSize: "cover",
                    }}
                ></div>
            </div>
            <div className={style.rightColumn}>
                <div className={style.text}>{props.content.textContent}</div>
                <div
                    className={style.secondImage}
                    style={{
                        background: `url(${imageBuilder.image(props.content.secondImage)}) no-repeat`,
                        backgroundSize: "cover",
                    }}
                ></div>
            </div>
        </div>
    );
};
