import { HeaderTitle } from "../../Components/HeaderTitle/HeaderTitle";
import style from "./PortfolioPage.module.css";
import { Portfolio } from "../../Modules/Portfolio/Portfolio";
import { Footer } from "../../Modules/Footer/Footer";
import { LetsBuild } from "../../Components/LetsBuild/LetsBuild";
import { useLocation } from "react-router-dom";

export const PortfolioPage = () => {
    const { state } = useLocation();
    return (
        <div className={style.main}>
            <HeaderTitle color="#000" title="Digimental Studio is an in-house production house based in London." />
            <Portfolio bgColor="#fff" color="#000" hideCta filter={state ? state.filter : false} />
            <LetsBuild />
            <Footer />
        </div>
    );
};
