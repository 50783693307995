import style from "./Home.module.css";
import { Header } from "../../Modules/Header/Header";
import { Headline } from "../../Modules/Headline/Headline";
import { Portfolio } from "../../Modules/Portfolio/Portfolio";
import { ServicesAccordion } from "../../Components/ServicesAccordion/ServicesAccordion";
import { LetsBuild } from "../../Components/LetsBuild/LetsBuild";
import { Footer } from "../../Modules/Footer/Footer";

export const Home = () => {
    return (
        <section className={style.main}>
            <Header />
            <Headline />
            <Portfolio isHome />
            <ServicesAccordion title="OUR CAPABILITIES" color="#000" borderColor="#000000" />
            <LetsBuild />
            <Footer />
        </section>
    );
};
