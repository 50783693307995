import { useState, useEffect } from "react";
import { UpRightArrow } from "../../assets/svg/UpRightArrow";
import { ListingItem } from "../../Components/ListingItem/ListingItem";
import style from "./Portfolio.module.css";
import plusIcon from "../../assets/svg/plusIcon.svg";
import { Link } from "react-router-dom";
import { client } from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

export const Portfolio = ({ isHome, hideCta, bgColor = "#151515", color = "#fff", filter = false }) => {
    const [arrowHovered, setArrowHovered] = useState(false);
    const [activeFilter, setActiveFilter] = useState(filter);
    const [portfolioData, setPortfolioData] = useState([]);
    const [filtersListArr, setFiltersListArr] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const imageBuilder = ImageUrlBuilder(client);

    const updateWindowWidthOnResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", updateWindowWidthOnResize);

        return () => {
            window.removeEventListener("resize", updateWindowWidthOnResize);
        };
    }, []);

    const fetchPortfolio = () => {
        client.fetch('*[_type == "portfolio"]').then((result) => {
            setPortfolioData(result);
        });
    };
    const fetchCategories = () => {
        client.fetch('*[_type == "categories"]').then((result) => {
            setFiltersListArr(result);
        });
    };

    useEffect(() => {
        fetchPortfolio();
        fetchCategories();
    }, []);

    return (
        <section className={style.portfolioWrapper} style={{ color: color, backgroundColor: bgColor }} id="portfolio">
            {!isHome && (
                <div className={style.filters}>
                    <ul>
                        <li
                            className={style.active}
                            onClick={(e) => {
                                setActiveFilter(false);
                                if (document.querySelector(`.${style.active}`))
                                    document.querySelector(`.${style.active}`).classList.remove(style.active);
                                e.target.classList.add(style.active);
                            }}
                        >
                            ALL
                        </li>
                        {filtersListArr.map((elem) => {
                            return (
                                <li
                                    onClick={(e) => {
                                        setActiveFilter(elem._id);
                                        if (document.querySelector(`.${style.active}`))
                                            document.querySelector(`.${style.active}`).classList.remove(style.active);
                                        e.target.classList.add(style.active);
                                    }}
                                >
                                    {elem.category}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
            <div className={style.portfolioItemsWrapper}>
                {isHome
                    ? portfolioData.map(
                          (elem) =>
                              elem.project.onHome && (
                                  <Link to={`/portfolio/${elem.project.slug.current}`}>
                                      <ListingItem
                                          imageUrl={imageBuilder.image(elem.project.header.image)}
                                          title={elem.project.title}
                                          desc={elem.project.subtitle}
                                          hoverString="View Case Study"
                                          imgHeight={windowWidth > 835 ? "23.6vw" : "63vw"}
                                          color={color}
                                      />
                                  </Link>
                              )
                      )
                    : portfolioData.map((elem) =>
                          activeFilter ? (
                              elem.project.category._ref === activeFilter && (
                                  <Link to={`/portfolio/${elem.project.slug.current}`}>
                                      <ListingItem
                                          imageUrl={imageBuilder.image(elem.project.header.image)}
                                          title={elem.project.title}
                                          desc={elem.project.subtitle}
                                          hoverString="View Case Study"
                                          imgHeight={windowWidth > 835 ? "23.6vw" : "63vw"}
                                          color={color}
                                      />
                                  </Link>
                              )
                          ) : (
                              <Link to={`/portfolio/${elem.project.slug.current}`}>
                                  <ListingItem
                                      imageUrl={imageBuilder.image(elem.project.header.image)}
                                      title={elem.project.title}
                                      desc={elem.project.subtitle}
                                      hoverString="View Case Study"
                                      imgHeight={windowWidth > 835 ? "23.6vw" : "63vw"}
                                      color={color}
                                  />
                              </Link>
                          )
                      )}
            </div>
            {!hideCta && (
                <>
                    <Link to="/portfolio">
                        <div
                            className={style.viewWorksWrapper}
                            onMouseEnter={(e) => {
                                setArrowHovered(true);
                                e.currentTarget.firstChild.classList.add(style.arrowHovered);
                                e.currentTarget.lastChild.classList.add(style.viewWorkHovered);
                            }}
                            onMouseLeave={(e) => {
                                setArrowHovered(false);
                                e.currentTarget.firstChild.classList.remove(style.arrowHovered);
                                e.currentTarget.lastChild.classList.remove(style.viewWorkHovered);
                            }}
                        >
                            <div className={style.arrow}>
                                {arrowHovered ? (
                                    <UpRightArrow hex="#000" height="1rem" />
                                ) : (
                                    <UpRightArrow hex="#fff" height="1rem" />
                                )}
                            </div>
                            <div className={style.viewWork}>VIEW ALL WORKS</div>
                        </div>
                    </Link>
                    <Link to="/portfolio" className={style.mobileWorksBtn}>
                        <div>
                            VIEW ALL WORKS
                            <img src={plusIcon} alt="plus icon" />
                        </div>
                    </Link>
                </>
            )}
        </section>
    );
};
