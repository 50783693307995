export const Logo = ({ color = "inherit", height, width, className, id }) => {
    return (
        <div className={className} id={id} style={{ height: height, width: width }}>
            <svg
                id="Group_255"
                data-name="Group 255"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 209.399 26.383"
            >
                <g id="Group_254" data-name="Group 254">
                    <path
                        id="Path_761"
                        data-name="Path 761"
                        d="M128.5,5.448a5.38,5.38,0,0,1-1.642,1.537,6.583,6.583,0,0,1-2.19.811,11.786,11.786,0,0,1-2.526.195v4.1h6.217V28.3h5.193V3.082h-4.169a5.454,5.454,0,0,1-.883,2.366"
                        transform="translate(-98.357 -2.482)"
                        fill={color}
                    />
                    <path
                        id="Path_762"
                        data-name="Path 762"
                        d="M405.679,3.081,399.9,19.951,394.13,3.081h-7.806V28.3h5.193V10.618L397.569,28.3h4.67l6.053-17.682V28.3h5.193V3.081Z"
                        transform="translate(-311.085 -2.481)"
                        fill={color}
                    />
                    <path
                        id="Path_763"
                        data-name="Path 763"
                        d="M1048.017,14.553a1.406,1.406,0,0,0,.287-.879,1.455,1.455,0,0,0-.422-1.059,1.431,1.431,0,0,0-1.068-.431H1044.5v4.668h.629V15.129h1.434l1.1,1.723h.754l-1.131-1.8a1.368,1.368,0,0,0,.736-.5m-2.888-.018V12.812h1.685a.8.8,0,0,1,.862.862.819.819,0,0,1-.261.611.933.933,0,0,1-.674.251Z"
                        transform="translate(-841.078 -9.811)"
                        fill={color}
                    />
                    <path
                        id="Path_764"
                        data-name="Path 764"
                        d="M18.7,6.19a9.856,9.856,0,0,0-3.638-2.33,12.977,12.977,0,0,0-4.539-.778H0V28.3H10.526a12.084,12.084,0,0,0,5.033-.971,10.008,10.008,0,0,0,3.568-2.667,11.211,11.211,0,0,0,2.137-4.026,17.494,17.494,0,0,0,.706-5.087,15.722,15.722,0,0,0-.866-5.475A10.771,10.771,0,0,0,18.7,6.19M16.247,19.612a6.482,6.482,0,0,1-1.413,2.35,5.13,5.13,0,0,1-2.013,1.289,7.107,7.107,0,0,1-2.33.389h-5.3V7.745H9.5a8.952,8.952,0,0,1,3.444.582,5.4,5.4,0,0,1,2.242,1.678,6.88,6.88,0,0,1,1.22,2.631,14.734,14.734,0,0,1,.37,3.444,10.327,10.327,0,0,1-.53,3.532"
                        transform="translate(0 -2.482)"
                        fill={color}
                    />
                    <path
                        id="Path_765"
                        data-name="Path 765"
                        d="M224.676,2.966A5.378,5.378,0,0,1,223.034,4.5a6.582,6.582,0,0,1-2.19.811,11.445,11.445,0,0,1-1.772.19q-.107-.213-.224-.418A9.413,9.413,0,0,0,216.43,2.3,10.655,10.655,0,0,0,213.145.583,12.244,12.244,0,0,0,209.382,0a12.742,12.742,0,0,0-5.21,1.025,11.125,11.125,0,0,0-3.9,2.826,12.6,12.6,0,0,0-2.438,4.22,15.772,15.772,0,0,0-.847,5.244,15.247,15.247,0,0,0,.847,5.139,12.278,12.278,0,0,0,2.438,4.15,11.155,11.155,0,0,0,3.9,2.773,12.944,12.944,0,0,0,5.21,1.006,9.286,9.286,0,0,0,3.638-.759,8.3,8.3,0,0,0,3.285-2.667l.566,2.862H220.4V13.741h-10.6v4.132H215.1a5.312,5.312,0,0,1-1.461,2.49,6.019,6.019,0,0,1-4.256,1.395,6.476,6.476,0,0,1-3.179-.724,6.081,6.081,0,0,1-2.119-1.925,8.352,8.352,0,0,1-1.182-2.7,12.848,12.848,0,0,1-.371-3.091A13.809,13.809,0,0,1,202.9,10.1a8.525,8.525,0,0,1,1.182-2.773A6.013,6.013,0,0,1,206.2,5.386a6.491,6.491,0,0,1,3.179-.724,5.55,5.55,0,0,1,3.427,1.06,4.7,4.7,0,0,1,1.4,1.722l.024.051c.044.093.085.19.126.287.03.072.062.153.094.239.042.114.084.228.121.348l.01.03q.074.243.135.5a5.756,5.756,0,0,1,.106.708h9.713V25.819h5.193V.6h-4.169a5.457,5.457,0,0,1-.883,2.366"
                        transform="translate(-158.622 0)"
                        fill={color}
                    />
                    <path
                        id="Path_766"
                        data-name="Path 766"
                        d="M547.007,28.3h18.508V23.638H552.2V17.457h12.22v-4.31H552.2v-5.4h13.315V3.081H547.007Z"
                        transform="translate(-440.474 -2.481)"
                        fill={color}
                    />
                    <path
                        id="Path_767"
                        data-name="Path 767"
                        d="M704.866,3.081h-25.5V19.829L669.1,3.081h-5.839V28.3h5.193V11.572L678.683,28.3h5.873V7.745h7.558V28.3h5.193V7.745h7.559Z"
                        transform="translate(-534.085 -2.481)"
                        fill={color}
                    />
                    <path
                        id="Path_768"
                        data-name="Path 768"
                        d="M864.784,3.081,856.151,28.3h5.193l1.6-4.662H872.8l1.6,4.662h5.193L870.952,3.081Zm-.429,16.425,3.513-10.263,3.513,10.263Z"
                        transform="translate(-689.411 -2.481)"
                        fill={color}
                    />
                    <path
                        id="Path_769"
                        data-name="Path 769"
                        d="M1036.7,3.057a4.152,4.152,0,1,0,4.152,4.153,4.157,4.157,0,0,0-4.152-4.153m0,7.721a3.569,3.569,0,1,1,3.569-3.569,3.573,3.573,0,0,1-3.569,3.569"
                        transform="translate(-831.451 -2.462)"
                        fill={color}
                    />
                    <path
                        id="Path_770"
                        data-name="Path 770"
                        d="M994.82,3.081h-5.193V28.3h12.983V23.639h-7.79Z"
                        transform="translate(-796.892 -2.481)"
                        fill={color}
                    />
                </g>
            </svg>
        </div>
    );
};
