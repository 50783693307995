import ImageUrlBuilder from "@sanity/image-url";
import { client } from "../../../../client";
import style from "./FullWidthPicture.module.css";

export const FullWidthPicture = (props) => {
    const imageBuilder = ImageUrlBuilder(client);

    return (
        <div className={style.moduleWrapper}>
            <div
                className={style.imageWrapper}
                style={{
                    marginTop: props.content.marginAround ? "0.84vw" : 0,
                    marginLeft: props.content.marginAround ? "4.2vw" : 0,
                    marginRight: props.content.marginAround ? "4.2vw" : 0,
                    background: `url(${imageBuilder.image(props.content.image)}) no-repeat`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            ></div>
        </div>
    );
};
