import { ServicesAccordion } from "../../Components/ServicesAccordion/ServicesAccordion";
import style from "./AboutServices.module.css";

export const AboutServices = () => {
    return (
        <div className={style.moduleWrapper}>
            <h2 className={style.title}>
                Enough about us.
                <br />
                Discover what Digimental® can do for you.
            </h2>
            <ServicesAccordion hideTitle color="#fff" borderColor="#ffffff" />
        </div>
    );
};
